export default {
    public: {
        error:{
            title: "Error",
            network: "网络访问错误",
        },
    },
    button: {
        refresh: 'Refresh',
        prev: 'Prev',
        next: 'Next',
        pay: 'Pay Now'
    },
    time: {
        title: {
            page: 'Date & Time',
            calendar: 'Pick a date'
        },
        button: {
            30: '30 mins',
            60: '1 hour',
            select: 'Select'
        },
        prompt:{
            roomInput: '请选择预约房间',
        },
        error: {
            getBookTimes: '获得预定时间错误，请尝试其他日期',
            timeContinuous: '请选择某个时间或连续的多个时间',
            timeOccupied: '预约时间已经占用，请您更换其他时间',
        },
    },
    name: {
        title: {
            page: 'Contact Details'
        },
        label: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email Address',
            mobile: 'Mobile',
            teamName: 'Team Name',
            common: "*If you've played with us before, please use the same team name and email address for cumulative game scores & ranking."
        },
        error: {
            checkName: 'Look like the team name already exist, please choose another name',
        },
    },
    people: {
        title: {
            page: 'Players Info'
        },
        label: {
            adult: 'Adults',
            child: 'Child (13 & under) requires an adult',
            youth: 'Child (14 - 15)',
            student: 'Student (must hold valid student ID)',
            common1: 'Please Note:',
            common2: 'Maximum people per session: 6',
            common3: 'Minimum people per session: 3 (2 during weekdays)',
        }
    },
    price: {
        title: {
            page: 'Payment'
        },
        error: {
            missBookTime: '缺少预约时间',
            missPrice: '缺少此组合的价格',
            timeOccupied: '预约时间已经占用，请您更换其他时间',
            lackAdult: '13岁以下必须有成人陪同',
            limitTotal: '总人数不能超过6人',
            limitWeekend: '周末至少3人以上',
            limitWeekday: '工作日至少2人以上',
        },
    },


};
