import { createI18n } from 'vue-i18n'
import zhCN from './lang/zn-CN.js'
import enUS from './lang/en-US.js'

const i18n = createI18n({
  legacy: false,
  locale: 'en-us', // 默认显示语言
  messages: {
    'zh-cn': zhCN,
    'en-us': enUS
  }
})

export default i18n
