<template>
    <div class="page">
        <div class="page_content">
            <a href="https://www.runninggrid.games" target="_blank">
                <img src="/img/logo2.png" style="height: 50px; margin-top: 20px" />
            </a>
            <img src="/img/gift_card_c.jpg" style="width: 100%" />

            <div class="card_content" :style="{ height: tableHeight + 'px' }">
                <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane label="Buy gift card" name="buy">
                        <div style="margin-bottom: 40px">
                            eGift card amount

                            <div style="display: flex; align-items: center; margin-top: 20px">
                                <el-radio-group v-model="amount.select" text-color="black" fill="#e0efc8"
                                    @change="amountChange">
                                    <el-radio-button label="NZD $25" value="25" />
                                    <el-radio-button label="NZD $50" value="50" />
                                    <el-radio-button label="NZD $100" value="100" />
                                    <el-radio-button label="NZD $150" value="150" />
                                    <el-radio-button label="Custom" value="0" />
                                </el-radio-group>
                            </div>

                            <div style="display: flex; flex-direction: column; margin-top: 15px"
                                v-if="amount.select === 'Custom'">
                                <span style="margin-bottom: 5px">{{ $t('card.label.custom') }}</span>

                                <div>
                                    <span>NZD $ &nbsp;</span>
                                    <el-input style="width: 378px" v-model="amount.input" @input="handleAmountChange"
                                        clearable />
                                </div>
                            </div>
                        </div>

                        <div style="margin-bottom: 20px">
                            Recipient details

                            <el-form :model="form" :rules="rules" label-position="top"
                                style="width: 100%; margin-top: 20px">
                                <el-form-item :label="$t('card.label.firstName')" prop="firstName" style="width: 46%">
                                    <el-input v-model="form.firstName" clearable></el-input>
                                </el-form-item>

                                <div style="display: flex; width: 100%">
                                    <el-form-item :label="$t('card.label.email')" prop="email" style="width: 46%">
                                        <el-input v-model="form.email" clearable></el-input>
                                    </el-form-item>

                                    <div style="flex: 1" />

                                    <el-form-item :label="$t('card.label.mobile')" prop="mobile" style="width: 46%">
                                        <el-input v-model="form.mobile" clearable></el-input>
                                    </el-form-item>
                                </div>

                                <div style="display: flex; width: 100%">
                                    <el-form-item :label="$t('card.label.reEmail')" prop="reEmail" style="width: 46%">
                                        <el-input v-model="form.reEmail" clearable></el-input>
                                    </el-form-item>
                                </div>

                                <el-form-item :label="$t('card.label.message')" prop="message" style="width: 46%">
                                    <el-input v-model="form.message" :rows="2" type="textarea" maxlength="100"
                                        show-word-limit clearable />
                                </el-form-item>

                                <el-checkbox v-model="form.isCopy" :label="$t('card.checkbox.copyTo')" size="large"
                                    style="color: white" />
                                <el-form-item :label="$t('card.label.copyTo')" prop="copyTo" style="width: 46%"
                                    v-if="form.isCopy">
                                    <el-input v-model="form.copyTo" clearable />
                                </el-form-item>

                                <el-form-item :label="$t('card.label.reCopyTo')" prop="reCopyTo" style="width: 46%"
                                    v-if="form.isCopy">
                                    <el-input v-model="form.reCopyTo" clearable />
                                </el-form-item>
                            </el-form>
                        </div>

                        <div style="margin-bottom: 20px">
                            Payment method

                            <div style="margin-top: 10px">
                                <el-radio-group v-model="form.paymentMethod">
                                    <el-radio label="PM01" size="large">
                                        <img style="width: 200px" src="../../assets/svg/logo-windcave.svg" />
                                    </el-radio>
                                    <el-radio label="PM02" size="large">
                                        <img style="width: 200px" src="../../assets/svg/logo-paypal.svg" />
                                    </el-radio>
                                </el-radio-group>

                                <div style="margin-top: 10px; font-size: 12px">{{ $t('pay.tip') }}</div>
                            </div>
                        </div>

                        <div style="color: white; font-size: 12px">
                            {{ $t('card.checkbox.agree1') }}
                            <a href="https://www.runninggrid.games/gift-card-terms-and-conditions" target="_blank">{{
                                $t('card.checkbox.agree2') }}</a>
                        </div>

                        <div style="margin-top: 10px; margin-bottom: 20px;">
                            <el-button class="next_button" @click="toPayment()">
                                {{ $t('button.continue') }}
                            </el-button>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Check balance" name="check">
                        <div style="display: flex; flex-direction: column; align-items: center">
                            <el-form label-position="top" style="width: 80%; margin-top: 20px">
                                <div style="display: flex; width: 100%">
                                    <el-form-item :label="$t('card.label.number')" prop="email" style="width: 40%">
                                        <el-input v-model="balance.number" clearable></el-input>
                                    </el-form-item>

                                    <div style="flex: 1" />

                                    <el-form-item :label="$t('card.label.pin')" prop="mobile" style="width: 40%">
                                        <el-input v-model="balance.pin" clearable></el-input>
                                    </el-form-item>
                                </div>

                                <div class="check-balance" v-if="amount.balance">
                                    <span>{{ $t('card.label.balance') }} {{ amount.balance }}</span>
                                </div>
                            </el-form>

                            <div style="margin-top: 50px">
                                <el-button class="next_button" @click="toCheck()">
                                    {{ $t('button.balance') }}
                                </el-button>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import $http from '../../http/index.js';
// const host = 'http://127.0.0.1:8080/manage';
const host = '';

export default {
    name: 'CardView',
    components: {},
    data() {
        return {
            tableHeight: 0,

            activeName: 'buy',
            amount: {
                select: 'NZD $25',
                input: 25,
                balance: ''
            },

            form: {
                firstName: '',
                email: '',
                reEmail: '',
                message: '',
                isCopy: true,
                copyTo: '',
                reCopyTo: '',
                mobile: '',
                amount: 25,
                paymentMethod: 'PM01'
            },

            //验证规则
            rules: {
                firstName: [
                    {
                        required: true,
                        message: this.$t('card.check.firstName')
                    }
                ],
                email: [
                    {
                        required: true,
                        message: this.$t('card.check.email')
                    }
                ],
                reEmail: [
                    {
                        required: true,
                        message: this.$t('card.check.reEmail')
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: this.$t('card.check.mobile')
                    }
                ],
                copyTo: [
                    {
                        required: true,
                        message: this.$t('card.check.copyTo')
                    }
                ],
                reCopyTo: [
                    {
                        required: true,
                        message: this.$t('card.check.reCopyTo')
                    }
                ]
            },

            balance: {
                number: '',
                pin: ''
            }
        };
    },
    unmounted() {
        window.removeEventListener('resize', this.getTableHeight);
    },
    mounted() {
        window.addEventListener('resize', this.getTableHeight);
        this.getTableHeight();
        // this.getRooms();
    },
    methods: {
        reset() {
            this.form = {};
        },

        /*************** 公共或系统方法 ***************/

        // 提示错误信息
        error(msg) {
            this.$alert(msg, this.$t('public.error.title'), { type: 'error', dangerouslyUseHTMLString: true });
        },

        info(msg) {
            this.$alert(msg, this.$t('public.info.title'), { type: 'info', dangerouslyUseHTMLString: true });
        },

        // 获得预约日期内容
        getTableTitle() {
            return dayjs(this.date).format('dddd, DD MMMM YYYY');
        },

        /*************** 公共或系统方法 ***************/

        /*************** 选择预约时间 ***************/

        // 动态改变预约时间列表高度
        getTableHeight() {
            this.tableHeight = window.innerHeight - 460;
            if (this.tableHeight < 360) {
                this.tableHeight = 360;
            }
        },

        handleClick(e) {
            if (e.paneName == 'check') {
                this.balance.number = "";
                this.balance.pin = "";
                this.amount.balance = '';
            }
        },

        handleAmountChange(e) {
            //过滤e字符
            e = e.replace('e', '');

            //判断输入是数字和.
            e = e.replace(/[^\d.]/g, '');

            this.amount.input = e;

            // 必须保证第一个为数字而不是.
            this.amount.input = this.amount.input.replace(/^\./g, '0.');

            // 保证只有出现一个.而没有多个.
            this.amount.input = this.amount.input.replace(/\.{2,}/g, '.');

            // 保证.只出现一次，而不能出现两次以上
            e = e.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');

            //只能输入两个小数
            this.amount.input = this.amount.input.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3');
            this.form.amount = this.amount.input;
        },

        amountChange(e) {
            if (e == 'NZD $25') {
                this.form.amount = 25;
            } else if (e == 'NZD $50') {
                this.form.amount = 50;
            } else if (e == 'NZD $100') {
                this.form.amount = 100;
            } else if (e == 'NZD $150') {
                this.form.amount = 150;
            }
        },

        toPayment() {
            if (this.form.email.toLowerCase() == this.form.reEmail.toLowerCase()) {
                if (!this.form.isCopy || (this.form.isCopy && this.form.copyTo.toLowerCase() == this.form.reCopyTo.toLowerCase())) {
                    $http.post(host + '/card/save', this.form)
                        .then(res => {
                            if (res.code == 0) {
                                // 跳转付款页面
                                if (res.data.href) {
                                    window.location.href = res.data.href;
                                }
                            } else if (res.code == 1) {
                                this.active = 3;
                                if (typeof res.info == 'string') {
                                    this.error(res.info);
                                } else if (typeof res.info == 'object') {
                                    this.error(Object.values(res.info).join('<br/>'));
                                }
                            }
                        }).catch(res => {
                            this.error(this.$t('public.error.network'));
                        });
                } else {
                    this.error(this.$t('card.error.checkCopyTo'));
                }
            } else {
                this.error(this.$t('card.error.checkEmail'));
            }
        },

        toCheck() {
            this.amount.balance = '';
            $http.post(host + '/card/checkBalance', this.balance)
                .then(res => {
                    if (res.code == 0) {
                        this.amount.balance = res.data.balance;
                    } else if (res.code == 1) {
                        this.active = 3;
                        if (typeof res.info == 'string') {
                            this.info(res.info);
                        } else if (typeof res.info == 'object') {
                            this.error(Object.values(res.info).join('<br/>'));
                        }
                    }
                }).catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        }
    }
};
</script>

<style>
.el-radio-button__inner {
    color: #e0efc8;
    margin-right: 25px;
    background-color: black;
    width: 100px;
    height: 45px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px !important;

    border: 1px solid;
    border-radius: 8px !important;
    border-color: #e0efc8;
}

.el-radio-button__inner:hover {
    color: black;
    background-color: #e0efc8;
}

.el-checkbox__inner {
    background: transparent;
    border: 1px solid #e0efc8;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #e0efc8;
    border: 1px solid #e0efc8;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #e0efc8;
}

.el-checkbox__inner::after {
    border: 3px solid black;
    border-left: 0;
    border-top: 0;
    left: 3px;
    top: 0px;
}

.el-tabs__nav.is-top {
    display: flex;
}

.el-tabs__nav.is-top>div {
    width: 100px;
    text-align: center;
}

.el-tabs__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px !important;
    padding: 0 !important;
    font-size: 28px;
    margin-bottom: 15px;
}

.el-tabs__item:hover {
    color: #e0efc8;
}

.el-tabs__active-bar {
    background-color: #e0efc8;
    /* 修改底部横杠的颜色 */
    height: 3px;
    /* 修改底部横杠的高度 */
}

.el-tabs__nav-wrap::after {
    background-color: #e0efc8;
    height: 3px;
}

.el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-tabs__item.is-active {
    color: white;
}

.el-tabs__item {
    color: #929292;
}
</style>

<style scoped>
.page {
    display: flex;
    justify-content: center;
    height: 100%;
}

.page_content {
    width: 1000px;
    min-width: 1000px;
}

.card_content {
    padding: 20px 30px 30px 30px;
}

.amount_button {
    width: 120px;
    height: 50px;
    font-size: 15px;
    font-weight: 700;
}

.next_button {
    margin-top: 20px;
    width: 300px;
    height: 35px;
    background-color: #e0efc8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.check-balance {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #e0efc8;
    border-radius: 10px;
    height: 60px;
}
</style>
