<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="145pt" height="110pt" viewBox="0 0 145 110" style=""
        preserveAspectRatio="xMidYMid meet">
        <g fill="#E0EFC8FF" stroke="#E0EFC8FF">
            <path
                d="M 89.253 39.244 L 52.009 76.488 34.503 59.003 L 16.997 41.519 9.256 49.260 L 1.515 57.001 27.002 82.502 L 52.489 108.003 97.735 62.765 L 142.982 17.526 135.264 9.763 C 131.020 5.493 127.311 2.000 127.022 2.000 C 126.733 2.000 109.737 18.760 89.253 39.244 " />
        </g>
        <g fill="#E1EFC95F" stroke="#E1EFC95F">
            <path d="M 89.500 38.493 L 52.500 75.512 89.500 38.493 L 126.500 1.475 89.500 38.493 " />
        </g>
        <g fill="#E0EFC8EF" stroke="#E0EFC8EF">
            <path d="M 24.500 80.500 L 47.000 103.000 24.500 80.500 L 2.000 58.000 24.500 80.500 " />
        </g>
        <g fill="#E0EFC8EF" stroke="#E0EFC8EF">
            <path d="M 35.000 59.000 L 52.000 76.000 35.000 59.000 L 18.000 42.000 35.000 59.000 " />
        </g>
        <g fill="#E1EFC89F" stroke="#E1EFC89F">
        <path d="M 135.000 9.000 L 143.000 17.000 135.000 9.000 L 127.000 1.000 135.000 9.000 " />
    </g>
    <g fill="#E1EFC89F" stroke="#E1EFC89F">
        <path d="M 49.000 105.000 L 51.500 107.500 49.000 105.000 L 46.500 102.500 49.000 105.000 " />
    </g>
</svg></template>