<template>
    <div class="page"
        style="background-image: url('img/backgroud.png'); background-size: contain; background-repeat: no-repeat; background-position: center center;">

        <div class="page_content">
            <el-row justify="center" align="middle" style="margin-top: calc(5vh); line-height: calc(20vh); ">
                <el-col>
                    <div class="title">Ranking</div>
                </el-col>
            </el-row>

            <el-row align="middle" class="item">
                <el-col :span="11">
                    <div class="box1 first">
                        <span>1</span>
                        <img src="img/first.png" class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[0] ? ranking[0].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[0] ? ranking[0].score : '' }} </span>
                    </div>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="11">
                    <div class="box1">
                        <span>6</span>
                        <div class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[5] ? ranking[5].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[5] ? ranking[5].score : '' }} </span>
                    </div>
                </el-col>
            </el-row>

            <el-row align="middle" class="item">
                <el-col :span="11">
                    <div class="box2 second">
                        <span>2</span>
                        <img src="img/second.png" class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[1] ? ranking[1].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[1] ? ranking[1].score : '' }} </span>
                    </div>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="11">
                    <div class="box2">
                        <span>7</span>
                        <div class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[6] ? ranking[6].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[6] ? ranking[6].score : '' }} </span>
                    </div>
                </el-col>
            </el-row>

            <el-row align="middle" class="item">
                <el-col :span="11">
                    <div class="box1 third">
                        <span>3</span>
                        <img src="img/third.png" class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[2] ? ranking[2].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[2] ? ranking[2].score : '' }} </span>
                    </div>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="11">
                    <div class="box1">
                        <span>8</span>
                        <div class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[8] ? ranking[8].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[8] ? ranking[8].score : '' }} </span>
                    </div>
                </el-col>
            </el-row>

            <el-row align="middle" class="item">
                <el-col :span="11">
                    <div class="box2">
                        <span>4</span>
                        <div class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[3] ? ranking[3].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[3] ? ranking[3].score : '' }} </span>
                    </div>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="11">
                    <div class="box2">
                        <span>9</span>
                        <div class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[9] ? ranking[9].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[9] ? ranking[9].score : '' }} </span>
                    </div>
                </el-col>
            </el-row>

            <el-row align="middle" class="item">
                <el-col :span="11">
                    <div class="box1">
                        <span>5</span>
                        <div class="img" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[5] ? ranking[5].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[5] ? ranking[5].score : '' }} </span>
                    </div>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="11">
                    <div class="box1">
                        <span>10</span>
                        <div class="img" style="padding-left: 32px;" />
                        <div class="team-name">
                            <div class="scroll-wrap">
                                <div class="scroll-item">
                                    {{ ranking[9] ? ranking[9].teamName : '' }}
                                </div>
                            </div>
                        </div>
                        <span> {{ ranking[9] ? ranking[9].score : '' }} </span>
                    </div>
                </el-col>
            </el-row>

        </div>
    </div>
</template>

<script>
import $http from '../../http/index.js';
// const host = 'http://localhost:8080/manage';
const host = '';

export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
            ranking: {},
            timer: {},
        };
    },
    unmounted() {
        clearInterval(this.timer);
    },
    mounted() {
        this.getData();

        let that = this;
        this.timer =
            setInterval(function () {
                that.getData();
            }, 60 * 1000);
    },
    methods: {

        /*************** 公共或系统方法 ***************/

        // 提示错误信息
        error(msg) {
            this.$alert(msg, this.$t('public.error.title'), { type: 'error', dangerouslyUseHTMLString: true })
        },

        /*************** 公共或系统方法 ***************/

        // 获得排行版列表
        getData() {
            $http.post(host + '/book/queryRanking')
                .then(res => {
                    if (res.code == 0) {
                        this.ranking = res.data
                    } else {
                        this.error(this.$t('public.error.execute'));
                    }
                }).catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        },
    }
};
</script>

<style scoped>
.page {
    display: flex;
    justify-content: center;
    height: 100%;
}

.page_content {
    width: 80%;
}

.title {
    color: #eee;
    text-shadow: 5px 5px 0 #666, 7px 7px 0 #eee;
    font-size: 100px;
    text-align: center;
}

.box1 {
    /* margin: 10px 100px; */
    padding: 10px 30px 10px 30px;
    /* width: calc(25vw); */
    line-height: calc(9vh);
    background: #434856;
    border: 4px solid #434856;
    display: flex;
    align-items: center;
    opacity: 0.8;
    font-size: 30px;
    font-style: italic;
    /* justify-content:center; */
}

.box1 {
    transform: skewX(170deg);
    -ms-transform: skewX(170deg);
    /* IE 9 */
    -moz-transform: skewX(170deg);
    /* Firefox */
    -webkit-transform: skewX(170deg);
    /* Safari 和 Chrome */
    -o-transform: skewX(170deg);
    /* Opera */

}

.box2 {
    /* margin: 10px 100px; */
    padding: 10px 30px 10px 30px;
    /* width: calc(25vw); */
    line-height: calc(9vh);
    background: #101526;
    border: 4px solid #101526;
    display: flex;
    align-items: center;
    opacity: 0.8;
    font-size: 30px;
    font-style: italic;
    /* justify-content:center; */
}

.box2 {
    transform: skewX(170deg);
    -ms-transform: skewX(170deg);
    /* IE 9 */
    -moz-transform: skewX(170deg);
    /* Firefox */
    -webkit-transform: skewX(170deg);
    /* Safari 和 Chrome */
    -o-transform: skewX(170deg);
    /* Opera */

}

.first {
    color: #f4e135;
}

.second {
    color: #989ebb;
}

.third {
    color: #fbc676;
}

.img {
    padding: 10px 20px 10px 50px;
    width: 64px;
    height: 64px;
}



.item {
    height: calc(13vh)
}


.team-name {
    width: calc(18vw);
    margin-right: 20px;
    /* border: 1px solid; */
}

.scroll-wrap {
    max-width: 100%;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
}

.scroll-item {
    animation: scroll linear 4s alternate infinite;
    float: left;
}

@keyframes scroll {
    0% {
        margin-left: 0;
        transform: translateX(0);
    }

    10% {
        margin-left: 0;
        transform: translateX(0);
    }

    90% {
        margin-left: 100%;
        transform: translateX(-100%);
    }

    100% {
        margin-left: 100%;
        transform: translateX(-100%);
    }
}
</style>
